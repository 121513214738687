import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import { renderOptions, documentToReactComponents, renderRichText } from "../components/renderRichText"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import CaseStudyCard from "../components/casestudyCard"
import {
  Container,
  Box,
  Space,
  Heading,
  Image,
  FlexList,
  Text
} from "../components/ui"
import * as styles from "./casestudy.css"

export interface CaseStudy {
  id: string
  title: string
  slug: string
  description: string
  image: Image
  html: string
  tags: [string]
  related: [CaseStudy]
}

interface QueryReturn {
  casestudy: CaseStudy
}

const CaseStudy: React.FC<PageProps<QueryReturn>> = ({ data: { casestudy } }) => {
  return (
    <Layout {...casestudy}>
      <Container width="narrow">
        <Box paddingY={5}>
          <Heading as="h1">
            {casestudy.title}
          </Heading>
          <Space size={3} />
          {
            documentToReactComponents(
              JSON.parse(casestudy.body.raw)
            )
            &&
            renderRichText(
              casestudy.body, renderOptions()
            )
          }
        </Box>
        {casestudy.related && (
          <Box paddingY={4}>
            <Text variant="subhead">More Projects</Text>
            <FlexList responsive wrap gap={0} gutter={3} variant="start">
              {casestudy.related.map((relatedCasestudy) => (
                <Box as="li" key={relatedCasestudy.id} padding={3} width="third" data-tags={relatedCasestudy.tags}>
                  <CaseStudyCard {...relatedCasestudy} />
                </Box>
              ))}
            </FlexList>
          </Box>
        )}
      </Container>
    </Layout>
  )
}

export default CaseStudy

export const Head: HeadFC<QueryReturn> = ({ data: { casestudy }, location }) => (
  <SEO title={casestudy.title} description={casestudy.description} pathname={location.pathname} />
)

export const query = graphql`
  query ($slug: String!) {
    casestudy: contentfulCaseStudy(slug: { eq: $slug }) {
      id
      slug
      title
      body {
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            description
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        raw
      }
      html
      description
      tags
      related {
        ...CaseStudyContent
      }
    }
  }
`